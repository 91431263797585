<!-- eslint-disable vue/no-mutating-props -->
<template>
  <hr />
  <div class="row mb-3 align-items-center">
    <div class="col">
      <div class="heading-06 text-neutral-07">Date facturare</div>
    </div>
    <div class="col-auto" v-if="!this.$store.state.auth.user">
      <div class="d-flex align-items-center pointer" @click="$emit('logIn')">
        <img src="../../../assets/images/icons/pink-user-circle-20x20.svg" alt="..." />
        <span class="ms-2 text-button-02 text-primary-05"> Intră în cont</span>
      </div>
    </div>
  </div>
  <Form :validation-schema="schema" v-slot="{ errors }" ref="cartDetailsFormRef">
    <div class="mb-3">
      <label class="text-neutral-07 text-button-02 mb-2">
        Tip client <span class="text-danger ms-1">*</span>
      </label>
      <div class="dropdown">
        <button
          type="button"
          class="btn-dropdown-outlined w-100 dropdown-toggle"
          id="dropdownClientType"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {{ user.ClientType.Name }}
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownClientType">
          <li
            @click="ChooseClientType(type)"
            v-for="(type, index) in clientTypes"
            :key="index"
            :class="{ active: user.ClientType == type }"
          >
            <div class="dropdown-item">{{ type.Name }}</div>
          </li>
        </ul>
      </div>
    </div>
    <hr />
    <div v-if="user.ClientType.IsJuridicalPerson === true" class="row">
      <div class="col-12 mb-3" :class="{ 'invalid-input': errors.companyName }">
        <label for="companyName" class="text-neutral-07 text-button-02 mb-2">
          Denumire companie<span class="text-danger ms-1">*</span>
        </label>
        <Field
          v-model="user.CompanyName"
          id="companyName"
          name="companyName"
          placeholder="Nume companie"
          class="form-control"
          type="text"
        ></Field>
        <ErrorMessage class="error-message" name="companyName" />
      </div>
      <div class="col-md mb-3 mb-md-0" :class="{ 'invalid-input': errors.companyCif }">
        <label for="companyCif" class="text-neutral-07 text-button-02 mb-2">
          CIF<span class="text-danger ms-1">*</span>
        </label>
        <Field
          v-model="user.CompanyCIF"
          id="companyCif"
          name="companyCif"
          placeholder="CIF"
          class="form-control"
          type="text"
        ></Field>
        <ErrorMessage class="error-message" name="companyCif" />
      </div>
      <div class="col" :class="{ 'invalid-input': errors.companyTradeNumber }">
        <label for="companyTradeNumber" class="text-neutral-07 text-button-02 mb-2">
          Nr. înregistrare Registrul Comerțului<span class="text-danger ms-1">*</span>
        </label>
        <Field
          v-model="user.CompanyTradeNumber"
          id="companyTradeNumber"
          name="companyTradeNumber"
          placeholder="Nr. înregistrare "
          class="form-control"
          type="text"
        ></Field>
        <ErrorMessage class="error-message" name="companyTradeNumber" />
      </div>
    </div>
    <hr v-if="user.ClientType.IsJuridicalPerson === true" />
    <div class="row mb-4">
      <div class="col-12 mb-3" :class="{ 'invalid-input': errors.name }">
        <label for="name" class="text-neutral-07 text-button-02 mb-2">
          Nume complet <span class="text-danger ms-1">*</span>
        </label>
        <Field
          v-model="user.UserName"
          id="name"
          name="name"
          placeholder="Nume complet"
          class="form-control"
          type="text"
          :disabled="this.$store.state.auth.user"
        ></Field>
        <ErrorMessage class="error-message" name="name" />
      </div>
      <div class="col-md mb-3 mb-md-0" :class="{ 'invalid-input': errors.phoneNumber }">
        <label for="phoneNumber" class="text-neutral-07 text-button-02 mb-2">
          Telefon <span class="text-danger ms-1">*</span>
        </label>
        <Field
          v-model="user.PhoneNumber"
          id="phoneNumber"
          name="phoneNumber"
          placeholder="Telefon"
          class="form-control"
          type="tel"
        ></Field>
        <ErrorMessage class="error-message" name="phoneNumber" />
      </div>
      <div class="col" :class="{ 'invalid-input': errors.email }">
        <label for="email" class="text-neutral-07 text-button-02 mb-2">
          Email<span class="text-danger ms-1">*</span>
        </label>
        <Field
          v-model="user.Email"
          id="user-cart-email"
          name="email"
          placeholder="Email"
          class="form-control"
          type="email"
          :disabled="this.$store.state.auth.user"
        ></Field>
        <ErrorMessage class="error-message" name="email" />
      </div>
    </div>
    <hr />
    <div class="row align-items-center mt-4 mb-4">
      <div class="col">
        <input
          :checked="user.Delivery === 'Pick-Up'"
          @click="user.Delivery = 'Pick-Up'"
          class="form-check-input me-2"
          type="radio"
          name="delivery-type"
          id="pick-up"
        />
        <label class="text-button-01 text-neutral-05 pointer" for="pick-up">
          Ridicare din magazin
        </label>
      </div>
      <Field v-model="user.Delivery" class="d-none" name="delivery"></Field>
      <ErrorMessage class="error-message" name="delivery" />
    </div>
    <hr />
    <div class="row pt-1 mb-4">
      <div class="col-md mb-3 mb-md-0" :class="{ 'invalid-input': errors.address }">
        <label for="address" class="text-neutral-07 text-button-02 mb-2"
          >Adresă<span class="text-danger ms-1">*</span></label
        >
        <Field
          v-model="deliveryLocation.Address"
          id="address"
          name="address"
          type="text"
          placeholder="Adresa..."
          class="form-control"
        ></Field>
        <ErrorMessage class="error-message" name="address" />
      </div>
      <div class="col">
        <label for="address2" class="text-neutral-07 text-button-02 mb-2"
          >Adresă 2 (opțional)</label
        >
        <Field
          v-model="deliveryLocation.Address2"
          id="address2"
          name="address2"
          placeholder="Adresa 2..."
          class="form-control"
          type="text"
        ></Field>
        <ErrorMessage class="error-message" name="address2" />
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-md mb-3 mb-md-0" :class="{ 'invalid-input': errors.state }">
        <label for="state" class="text-neutral-07 text-button-02 mb-2"
          >Județ<span class="text-danger ms-1">*</span></label
        >
        <Field v-model="deliveryLocation.State" name="state" id="stateField">
          <VueMultiselect
            v-model="deliveryLocation.State"
            id="state-select"
            label="Name"
            track-by="Id"
            placeholder="Județ.."
            open-direction="bottom"
            :options="states"
            :searchable="true"
            select-label=""
            :close-on-select="true"
            :max-height="600"
            @search-change="SearchState"
            @open="SearchState()"
            @remove="RemoveCity()"
            @select="RemoveCity()"
            class="custom-multiselect accentuate"
          >
          </VueMultiselect>
        </Field>
        <ErrorMessage class="error-message" name="state" />
      </div>
      <div class="col" :class="{ 'invalid-input': errors.city }">
        <label for="city" class="text-neutral-07 text-button-02 mb-2"
          >Oraș<span class="text-danger ms-1">*</span></label
        >
        <Field v-model="deliveryLocation.City" name="city" id="cityField">
          <VueMultiselect
            ref="vueMultiselectCityRef"
            v-model="deliveryLocation.City"
            id="city"
            label="DisplayName"
            track-by="Id"
            placeholder="Oraș.."
            open-direction="bottom"
            :options="cities"
            :searchable="true"
            select-label=""
            :internal-search="false"
            :clear-on-select="false"
            :close-on-select="true"
            :max-height="600"
            @search-change="SearchCity"
            @open="SearchCity()"
            @select="SelectPostalCode()"
            class="custom-multiselect accentuate"
          >
          </VueMultiselect>
        </Field>
        <ErrorMessage class="error-message" name="city" />
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-md mb-3 mb-md-0" :class="{ 'invalid-input': errors.country }">
        <label for="country" class="text-neutral-07 text-button-02 mb-2">
          Țară<span class="text-danger ms-1">*</span>
        </label>
        <Field
          v-model="deliveryLocation.Country"
          id="country"
          name="country"
          placeholder="Țara..."
          type="text"
          class="form-control"
        ></Field>

        <ErrorMessage class="error-message" name="country" />
      </div>
      <div class="col" :class="{ 'invalid-input': errors.postalCode }">
        <label for="postalCode" class="text-neutral-07 text-button-02 mb-2">
          Cod poștal <span class="text-danger ms-1">*</span>
        </label>
        <Field
          v-model="deliveryLocation.PostalCode"
          id="postalCode"
          name="postalCode"
          placeholder="Cod poștal..."
          class="form-control"
          type="text"
        ></Field>
        <ErrorMessage class="error-message" name="postalCode" />
      </div>
    </div>
    <hr v-if="settings.PickUpFieldDate" />
    <div v-if="settings.PickUpFieldDate" :class="{ 'invalid-input': errors.pickUpDay }">
      <label class="text-neutral-07 text-button-02 mb-2">
        Alege în ce zi vei ridica comanda <span class="text-danger ms-1">*</span>
      </label>
      <Field v-slot="{ field }" name="pickUpDay" id="pickUpDay" v-model="user.PickUpDay">
        <VueDatePicker
          v-bind="field"
          v-model="user.PickUpDay"
          placeholder="Data"
          :enableTimePicker="false"
          autoApply
          class="custom-datepicker show-other-day"
          format="dd-MM-yyyy"
          :min-date="
            new Date(new Date().setDate(new Date().getDate() + this.settings.MinPickUpFieldDate))
          "
          aria-label="Pick-up-day"
          :allowed-dates="allowedDates"
        ></VueDatePicker>
      </Field>
      <ErrorMessage class="error-message" name="pickUpDay" />
    </div>
    <hr />
    <label for="clarifications" class="text-neutral-07 text-button-02 mb-2 mt-2">
      Alte precizări
    </label>
    <Field
      v-model="deliveryLocation.Clarifications"
      id="clarifications"
      name="clarifications"
      as="textarea"
      cols="30"
      rows="2"
      class="form-control"
      placeholder="Precizări..."
    ></Field>
    <ErrorMessage class="error-message" name="clarifications" />
  </Form>
</template>

<script>
/* eslint-disable */
import { Field, ErrorMessage, Form } from 'vee-validate';
import * as yup from 'yup';
import VueMultiselect from 'vue-multiselect';

export default {
  name: 'CartDetails',
  emits: ['getState', 'getCity', 'form-is-available', 'logIn'],
  props: {
    user: Object,
    location: Object,
    states: Array,
    cities: Array,
    deliveryLocation: Object,
    settings: {
      type: Object,
      default: {
        MinPickUpFieldDate: 0,
        PickUpFieldDate: null,
      },
    },
  },
  data() {
    return {
      state: {},
      clientTypes: [
        {
          Name: 'Persoană fizică',
          IsJuridicalPerson: false,
        },
        {
          Name: 'Persoană juridică',
          IsJuridicalPerson: true,
        },
      ],
      allowedDates: [
        Date.parse('April 30, 2024'),
        Date.parse('May 01, 2024'),
        Date.parse('May 02, 2024'),
        Date.parse('May 03, 2024'),
      ],
    };
  },
  components: {
    Field,
    ErrorMessage,
    Form,
    VueMultiselect,
  },
  methods: {
    ChooseClientType(type) {
      this.user.ClientType = type;
      if (this.user.ClientType.IsJuridicalPerson === false) {
        this.user.CompanyCIF = '';
        this.user.CompanyName = '';
        this.user.CompanyTradeNumber = '';
      }
    },
    SearchState(searchQuery) {
      if (typeof searchQuery === 'string') {
        this.$emit('getState', searchQuery);
      } else {
        this.$emit('getState');
      }
    },
    SearchCity(searchQuery) {
      if (typeof searchQuery === 'string') {
        this.$emit('getCity', searchQuery);
      } else {
        this.$emit('getCity');
      }
    },
    RemoveCity() {
      this.$refs.vueMultiselectCityRef.removeLastElement();
      // eslint-disable-next-line vue/no-mutating-props
      this.deliveryLocation.PostalCode = '';
    },
    SelectPostalCode() {
      // eslint-disable-next-line vue/no-mutating-props
      this.deliveryLocation.PostalCode = this.deliveryLocation.City.ZipCode;
    },
  },
  computed: {
    schema() {
      return yup.object({
        companyName: yup
          .string()
          .notRequired()
          .test('validate-company-name', 'Numele companiei este obligatoriu.', (value) => {
            if (this.user.ClientType.IsJuridicalPerson === true) {
              if (!value) return false;
              return true;
            }
            return true;
          }),
        companyCif: yup
          .string()
          .notRequired()
          .test('validate-company-cif', 'CIF-ul companiei este obligatoriu.', (value) => {
            if (this.user.ClientType.IsJuridicalPerson === true) {
              if (!value) return false;
              return true;
            }
            return true;
          }),
        companyTradeNumber: yup
          .string()
          .notRequired()
          .test(
            'validate-company-cif',
            'Nr. înregistrare al companiei este obligatoriu.',
            (value) => {
              if (this.user.ClientType.IsJuridicalPerson === true) {
                if (!value) return false;
                return true;
              }
              return true;
            }
          ),
        name: yup
          .string()
          .required('Numele este obligatoriu,')
          .test('verify-name', 'Vă rugăm introduceți numele complet.', (name) => {
            const verifyName = name.split(' ');
            if (verifyName.length < 2) {
              return false;
            }
            return true;
          }),
        phoneNumber: yup
          .string()
          .required('Numărul de telefon este obligatoriu.')
          .trim()
          .test('validate-phone', 'Numărul de telefon nu este valid.', (phoneNumber) => {
            if (!phoneNumber) return true;
            if (phoneNumber.length < 10) return false;
            return /^([\\+]?40[-]?|[0])?[1-9][0-9]{8}$/.test(phoneNumber);
          }),
        email: yup
          .string()
          .email('Acest e-mail nu este corect.')
          .required('Email-ul este obligatoriu.'),
        delivery: yup
          .string()
          .required('Pentru a merge la pasul următor vă rugam selectați una dintre opțiuni.'),
        address: yup
          .string()
          .notRequired()
          .test('verify-addres', 'Adresa este obligatorie.', (value) => {
            // if (this.user.Delivery === 'Pick-Up') {
            //   return true;
            // }
            if (!value) {
              return false;
            }
            return true;
          }),
        address2: yup.string().notRequired(),
        country: yup
          .string()
          .notRequired()
          .test('verify-country', 'Țara este obligatorie.', (value) => {
            // if (this.user.Delivery === 'Pick-Up') {
            //   return true;
            // }
            if (!value) {
              return false;
            }
            return true;
          }),
        state: yup
          .object()
          .notRequired()
          .test('verify-state', 'Județul este obligatoriu.', (value) => {
            // if (this.user.Delivery === 'Pick-Up') {
            //   return true;
            // }
            if (this.deliveryLocation.State && this.deliveryLocation.State.Name) {
              return true;
            }
            if (!value) {
              return false;
            }
            return true;
          }),
        city: yup
          .object()
          .notRequired()
          .test('verify-city', 'Orașul este obligatoriu.', (value) => {
            // if (this.user.Delivery === 'Pick-Up') {
            //   return true;
            // }
            if (this.deliveryLocation.City && this.deliveryLocation.City.Name) {
              return true;
            }
            if (!value) {
              return false;
            }
            return true;
          }),
        postalCode: yup
          .string()
          .notRequired()
          .matches(/^(|.{5,6})$/, 'Codul poștal nu este valid')
          .test('verify-postal-code', 'Codul poștal nu este valid', (value) => {
            // if (this.user.Delivery === 'Pick-Up') {
            //   return true;
            // }
            if (this.deliveryLocation.State && this.deliveryLocation.State.Name) {
              return /^\d+$/.test(value);
            }
            if (!value) {
              return false;
            }
            return /^\d+$/.test(value);
          }),
        pickUpDay: yup
          .string()
          .required('Ziua ridicării comenzii este obligatorie')
          .test('is-required', 'Ziua ridicării comenzii este obligatorie.', (date) => {
            if (!date) {
              return false;
            }
            if (this.settings.PickUpFieldDate == false) {
              return true;
            }
            return true;
          }),
        clarifications: yup.string().notRequired(),
      });
    },
  },
  mounted() {},
};
</script>

<style scoped>
.btn-dropdown-outlined {
  border-color: var(--neutral-4);
}
hr {
  color: var(--neutral-4) !important;
}
</style>
